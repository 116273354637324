/* ======================================================================
 layout
====================================================================== */

/* =================================
 l_container
================================= */
.l_container {
}

/* =================================
 l_header
================================= */
.l_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	z-index: 10;
	height: 110px;
	width: 100%;
	max-width: 1140px;
	margin: 0 auto -110px;
	padding: 0 20px;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  top: 0;
  .list {
    display: flex;
    margin: 0 10px 0 auto;
    .item {
      padding: 0 20px;
      & + .item {
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          top: 50%;
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
          content: "";
          width: 1px;
          height: 52px;
          background-color: #E6E6E6;
        }
      }
      .link {
        color: $color_2;
        font-size: 16px;
        font-weight: bolder;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .info {
    margin-right: 20px;
    text-align: center;
    .txt {
      font-size: 14px;
      font-weight: bold;
    }
    .tel {
      font-family: $font_2;
      font-size: 28px;
      font-weight: 900;
      position: relative;
      padding-left: 27px;
      &::before {
        content: "";
        background-image: url(../img/common/tel_icon01.svg);
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        width: 21px;
        height: 21px;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
    .time {
      font-size: 13px;
      border: 1px solid #333;
      border-radius: 4px;
      padding: 4px 9px;
      text-align: center;
      .num {
        font-family:  $font_2;
      }
    }
  }
  .contact_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80px;
    width: 130px;
    border-radius: 6px;
    background: $green;
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    color: #fff;
    transition: 0.3s;
    transform: rotateZ(0.03deg);
    &:before {
      content: "";
      display: block;
      width: 23.9px;
      height: 19.1px;
      margin: 0 0 6px;
      background: url(../img/common/mail_icon.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:visited,
    &:active {
      color: #fff;
    }
    @include pc_tab {
      &:hover {
        background-color: #8CB808;
      }
    }
  }
  @include pc_tab {
    .logo {
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
    }
    .info {
      .tel {
        pointer-events: none;
      }
    }
  }
  @include mq_max(1184) {  
  /*@include sp {*/
    height: 60px;
    margin: 0 auto -60px auto;
    padding: 0 0 0 15px;
    .logo {
      img {
        width: 80px;
        object-fit: contain;
      }
    }
    .list {
      display: none;
    }
    .info {
      display: none;
    }
    .contact_btn {
      display: none;    
    }
  }
}

.hambarger_btn {
  display: none;
  position: relative;
  width: 60px;
  height: 60px;
  background: $color_4;
  .open_btn {
    display: flex;
    flex-wrap: wrap;
    /*align-items: center;*/
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding-left: 14.7px;
    & > * {
      margin: 4px 0 0 0;
    }
  }
  .close_btn {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: $color_2;
    .item {
      width: 20px;
      &:nth-child(1) {
        position: absolute;
        left: 18px;
        top: 23px;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        position: absolute;
        left: 18px;
        top: 23px;
        transform: rotate(-45deg);
        /*width: 24px;*/
        width: 20px;      }
    }
    .txt {
      position: absolute;
      bottom: 13px;
      left: 14.7px;
    }
  }
  .item {
    display: block;
    width: 24px;
    height: 2px;
    background: #fff;
    &:nth-of-type(n+2) {
      width: 17px;
    }
  }
  .txt {
    font-size: 10px;
    font-weight: 800;
    color: #fff;
    margin-top: 7px;
  }
  @include mq_max(1184) {  
  /*@include sp {*/
    display:block;
  }
}

.sp_menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  width: 100%;
  /*height: 100vh;
  height: calc(var(--vh, 1vh) * 100);*/
  height: 100%;
  padding: 60px 0 0;
  background-color: #fff;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  /*padding: 81px 0;*/
  .list {
    width: 100%;  
    font-size: 18px;
    font-weight: bold;
    background: $color_4;
    padding: 21px 15px;
    .item {
      width: 100%;
      height: 61px;
      &:nth-of-type(n+2) .link {
        border-top: 1px solid rgba(255,255,255,0.2);
      }
      .link {
        color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        padding: 25px 0;
        height: 100%;
        font-weight: normal;
        &::before {
          background-image: url(../img/common/arr_icon01.svg);
          background-size: contain;
          background-repeat: no-repeat;
          content: "";
          display: block;
          height: 24px;
          width: 24px;
          margin-right: 8px;
        }
      }
    }
  }
  .info {
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 0 15px;
    .item {
      padding: 30px 15px;
      &:nth-child(2) {
        border-top: 1px solid #E6E6E6;      
      }
      &:nth-child(3) {
        border-top: 1px dotted #ccc;      
      }
      .subttl {
        font-size: 18px;
        line-height: 30px;
        color: $color_2;
        font-weight: bold;        
      }
      .link {
        margin-top: 15px;
        max-width: 400px;
      }
      .time {
        font-size: 18px;
        border: 1px solid #333;
        border-radius: 4px;
        text-align: center;
        padding: 4px;
        max-width: 310px;
        margin: 10px auto 0;
      }
    }
  }
  .c_btn_1:link {
    width: 100%;
    max-width: 345px;
    margin: 20px auto 0 auto;
  }
}



/* =================================
 l_breadcrumbs
================================= */
.l_breadcrumbs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
  font-size: 16px;
  padding: 12px 0 ;
  border-bottom: 1px solid #E6E6E6;
  .breadcrumbs {
    width: 1100px;
    margin: 0 auto;  
  }
  a {
    color: #4E7FB9;
    text-decoration: underline;
    padding-right: 24px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: 5px;
      width: 7px;
      height: 7px;
      border-top: 1px solid #242424;
      border-right: 1px solid #242424;
      top: 10px;
      -webkit-transform: translateX(-50%) rotate(45deg);
      transform: translateX(-50%) rotate(45deg);
    }
  }
  .breadcrumb_last {
    color: #222222;
    /*padding-left: 7px;*/
  }
  
  @include mq_max(1184) {
    padding: 12px 15px;
  }
     
  @include sp {
    overflow-x: scroll;
    .breadcrumbs {
      width: 100%;
      white-space: nowrap;
    }
    a {
      /*text-decoration: underline;
      padding-right: 20px;*/
    }
    .breadcrumb_last {
      padding-right: 15px;
      /*padding-left: 7px;*/
    }
  }
}

/* =================================
 l_main_visual
================================= */
.l_main_visual {
	width: 100%;
	min-height: 343px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
  .inner {
    margin: 0 auto;
    padding-top: 198px;
    text-align: center;
  }
  .head {
    font-size: 43px;
    line-height: 65px;
    color: #fff;
    font-weight: bold;
  }
  &.l_case_main_visual {
    .inner {
      width: $content_width;
      text-align: left;
      padding-top: 146px;
      position: relative;
    }
    .subhead {
      display: flex;
      align-items: center;
      .cat {
        color: #fff;
        background-color: $color_4;
        font-size: 20px;
        line-height: 30px;
        padding: 5px;
        position: relative;
        margin-right: 20px;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -21px;
          transform: translateY(-50%);
          width: 1px;
          height: 33px;
          background-color: rgba(255,255,255,0.46);
        }
        &.interior {
          background-color: $color_4;        
        }
        &.exterior {
          background-color: $color_2;        
        }
        &.plan {
          background-color: $color_5;        
        }
        &.others {
          background-color: $color_1;        
        }
      }
      .cate_list {
        display: flex;
        padding-left: 20px;
        .item {
          font-size: 14px;
          line-height: 20px;
          color: $color_2;
          padding: 5px;
          border-radius: 4px;
          background: #fff;
          border: 1px solid #032076;
          &:nth-of-type(n+2) {
            margin-left: 5px;
          }
        }
      }          
    }
    .head {
      font-size: 34px;
      line-height: 50px;
      margin-top: 14px;
    }
    .day_box{
      position: absolute;
      top: 146px;
      right: 0;
      .day {
        color: #fff;
        font-size: 16px;
        position: relative;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          display: block;
          width: 15px;
          height: 15px;
          /*margin: 0 0 6px;*/
          background: url(../img/common/clock_icon.svg);
          background-repeat: repeat;
          background-size: auto;
          background-size: contain;
          background-repeat: no-repeat;
          margin: 2px 5px 0 0;
        }
      }
    }
  }
  @include mq_max(1184) {
    .inner {
      padding-top: 170px;
    }
    &.l_case_main_visual {
      .inner {
        width: 100%;
        padding: 126px 15px 0;        
      }
      .day_box{
        position: absolute;
        top: 126px;
        right: 15px;
      }
    }
    
  }
  @include sp {
    height: 140px;
    margin-top: 60px;
    min-height: unset;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .inner {
      padding-top: 0;
    }
    .head {
      font-size: 30px;
      line-height: 42px;
    }
    &.l_case_main_visual {
      height: 215px;
      .inner {
        width: 100%;
        padding: 20px 15px 0;
        overflow-x: scroll;
        height: 100%;
      }
      .subhead {
        white-space: nowrap;
          .cat {
          font-size: 16px;
          padding: 5px;
          margin-right: 10px;
          &::after {
            right: -11px;
          }
        }
        .cate_list {
          padding-left: 10px;
          padding-right: 10px;
          .item {
            font-size: 13px;
          }
        }
      }
      .head {
        font-size: 20px;
        line-height: 30px;
        margin-top: 20px;
      }
      .day_box{
        position: relative;
        top: auto;
        right: auto;
        margin-top: 10px;
        .day {
          justify-content: flex-end;
        }
      }
    }
  }
}


/* =================================
 l_content
================================= */
.l_content {
}

/* =================================
 l_main
================================= */
.l_main {
}

/* =================================
 l_side
================================= */
.l_side {
}


/* =================================
 l_support_sec
================================= */
.l_support_sec {
  width: 100%;
  position: relative;
  /*margin-top: 40px;*/
  transform:translate3d(0,0,0);
  .inner {
    min-height: 653px;
    background-image: url(../img/common/s_bg01.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 70px 0;
  }
  .ttl {
    margin: 0 0 40px;
    font-size: 34px;
    font-weight: bold;
    line-height: 62px;
    text-align: center;
    color: #fff;
    position: relative;
    .sm_txt {
      font-size: 30px;
    }
    .accent {
      background: url(../img/common/accent_line02.png);
      background-repeat: no-repeat;
      background-position: bottom -4px left;
    }    
  }
  .contact {
    padding: 32px 0;
    max-width: $content_width;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 20px;
    .p {
      font-size: 18px;
      font-weight: bold;
      line-height: 30px;
      color: $color_2;
      text-align: center;
      margin-bottom: 20px;
    }
    .bottom {
      display: flex;
      border-top: 1px solid #E6E6E6;
      margin-top: 30px;
      .info {
        width: 50%;
        text-align: center;
        margin-top: 42px;
        .txt {
          font-size: 18px;
          font-weight: bold;
          line-height: 30px;
          color: $color_2;
        }
        .tel {
          position: relative;
          padding-left: 36px;
          display: inline-block;
          margin-top: 5px;
          &::before {
            content: "";
            background-image: url(../img/common/tel_icon01.svg);
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
          .num {
            font-size: 39px;
            font-family: $font_2;
            font-weight: bold;
          }
        }
        .time {
          font-size: 18px;
          border: 1px solid #333;
          border-radius: 4px;
          padding: 4px 9px;
          text-align: center;
          max-width: 310px;
          margin: 5px auto 0;
          .num {
            font-family:  $font_2;
          }
        }
      }
      .right {
          border-left: 1px solid #ccc;
      }
    }
  }
  
  @include pc_tab {
    .contact {
      .bottom {
        .info {
          .tel {
            pointer-events: none;
          }
        }
      }
    }
  }
  
  @include mq_max(1184) {  
    .inner {
      padding: 70px 15px;
    }
    .contact {
      max-width: unset;
    }  
  }
  
  @include sp {
    z-index: 2;
    .inner {
      min-height: 819px;
      background-image: url(../img/common/st_bg01_sp.jpg);
      padding: 50px 15px;
    }
    .ttl {
      margin: 0 0 30px;
      font-size: 20px;
      line-height: 32px;
      .sm_txt {
        font-size: 20px;
      }
      .accent {
        background-size: contain
      }    
    }
    .contact {
      border-radius: 10px;
      padding: 32px 0;
      .top {
        padding: 0 15px;
      }
      .p {
        margin-bottom: 16px;
      }
      .bottom {
        flex-direction: column;
        padding: 0 15px;
        .info {
          width: 100%;
          text-align: center;
          margin-top: 32px;
          .tel {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70px;
            width: 100%;
            border-radius: 9999px;
            font-size: 30px;
            font-weight: bold;
            font-family: $font_2;
            line-height: 36px;
            transition: 0.3s;
            margin: 16px auto 0;
            position: relative;
            background-color: $color_5;
            padding-left: 0;            
            &:before {
              content: none;
            }
            .num {
              position: relative;
              display: flex;
              align-items: center;
              padding-left: 29px;
              color: #fff;
              font-size: 30px;
              &:before {
                content: "";
                background-image: url(../img/common/tel_icon02.svg);
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                width: 19.1px;      
                height: 31.3px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }
            &:visited,
            &:active {
              color: #fff;
            }
          }
          .time {
            padding: 4px;
            max-width: 310px;
            margin: 10px auto 0;
            .num {
              font-weight: bold;
            }
          }
        }
        .right {
            border-left: none;
        }
      }
    }
  }
}


/* =================================
 l_relation_sec
================================= */
.l_relation_sec {
  width: 100%;
  position: relative;
  .inner {
    position: relative;
  }
  .list {
    display: flex;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
      width: 100%;
      overflow: hidden;
      .photo {
        width: 100%;
        .link {
          display: block;
        }
      }
      .img {
        transform: translate3d(0,0,0) scale(1.00);
        transition: transform .7s cubic-bezier(.19,.82,.27,1);
        width: 100%;
        height: 390px;
        object-fit: cover;
      }
      &.item:nth-of-type(n+2) {
        margin-left: 1px;
      }
      .txt {
        width: 100%;
        color: #fff;
        position: absolute;
        top: 0;
        .subttl {
          font-size: 30px;
          line-height: 40px;
          margin-top: 108px;
          font-weight: bold;
        }
        .p {
          margin-top: 10px;
          font-size: 18px;
          line-height: 30px;
        }
        .detail {
          font-size: 18px;
          line-height: 30px;
          width: 200px;
          position: relative;
          display: flex;
          text-align: left;
          margin: 71px auto 0;
          margin: 78px auto 0;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #fff;
          padding-bottom: 16px;
          &:after {
            background-image: url(../img/common/arr_icon01.svg);
            background-size: contain;
            background-repeat: no-repeat;
            content: "";
            display: block;
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
  
  @include pc_tab {
    .item {
      .photo {
        .link {
          background-color: #000;
          .img {
            opacity: 0.8;
            transition: .4s;
          }
          &:hover .img{
            transform: translate3d(0,0,0) scale(1.07);
            transition: transform .4s;
            opacity: 1;
          }
        }
      }
    }
  }
  
  @include sp {
    .list {
      flex-direction: column;
      .item {
        height: 150px;
        .photo {
          height: 100%;
          .link {
            height: 100%;
          }
        }
        .img {
          transform: translate3d(0,0,0) scale(1.00);
          transition: transform .7s cubic-bezier(.19,.82,.27,1);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &.item:nth-of-type(n+2) {
          border-top: 1px solid #fff;
          margin-left: 0;
        }
        .txt {
          text-align: left;
          padding: 18px 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .subttl {
            font-size: 24px;
            line-height: 34px;
            margin-top: 0;
          }
          .lead{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;          }
          .p {
            margin-top: 7px;
            font-size: 16px;
            line-height: 26px;
            margin-right: 15px;
          }
          .detail {
            font-size: 16px;
            line-height: 26px;
            width: 140px;
            margin: 0;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 10px;
            flex-shrink: 0;
            &:after {
              height: 18px;
              width: 18px;
            }
          }
        }
      }
    }
  }
  @include mq_max(340) {
    .list {
      .item {
        .txt {
          .p {
          }
        }
      }
    }
  }
  
}


/* =================================
 l_footer
================================= */
.l_footer {
  width: 100%;
  position: relative;
  background-color: #FAFAFA;
  transform:translate3d(0,0,0);
  .inner {
    width: $content_width;
    margin: 0 auto;
    padding: 60px 0 50px
  }
  .top {
    display: flex;
    justify-content: space-between;  
  }
  .company_info {
    flex-shrink: 0;
    width: 530px;
    margin-right: 37px;
    .link {
      display: block;
    }
    .p {
      margin-top: 15px;
      font-size: 16px;
      line-height: 26px;
    }
    .list {
      display: flex;
      margin-top: 23px;
      .item {
        &:nth-of-type(n+2) {
          margin-left: 10px;
        }
      }
    }
  }
  .right {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 18px;
    .list {
      width: 256px;
      .item {
        padding-bottom: 15px;
        border-bottom: 1px solid #ccc;
        &:nth-of-type(n+2) {
          padding-top: 21px;
        }
        .link {
          color: $color_1;
          &::after {
          }
        }
      }
    }
    .left_box {
      margin-right: 20px;
    }
  }
  .copyright {
    width: 100%;
    text-align: right;
    font-size: 16px;
    line-height: 26px;
    font-family: $font_2;
  }
  
  @include mq_max(1184) {  
  /*@include sp {*/
    margin-bottom: -60px;
    z-index: 2;
    .inner {
      padding: 40px 15px 30px;
      width: auto;
    }
    .top {
      flex-direction: column;
    }
    .company_info {
      order: 2;
      width: auto;
      margin: 40px 0 0;
      .link {
        text-align: center;
        .img {
          width: 120px;
        }
      }
      .p {
        margin-top: 20px;
        font-size: 18px;
        line-height: 30px;
      }
      .list {
        margin-top: 20px;
        .item {
          width: 100%;
          &:nth-of-type(n+2) {
            margin-left: 8px;
          }
          .btn_link {
            width: 100%;
          }
        }
      }
    }
    .right {
      order: 1;
      flex-direction: column;
      .list {
        width: auto;
        margin: 0;
        .item {
          &:nth-of-type(n+2) {
            padding-top: 15px;
          }
          .link {
            color: $color_1;
            &::after {
            }
          }
        }
      }
      .right_box {
        .item {
          padding-top: 15px;
        }
      }
    }
    .copyright {
      text-align: center;
      margin-top: 38px;
    }
  }
}

/* =================================
 l_fix_btn
================================= */
.l_fix_btn {
  display: none;
  @include sp {
    display: block;
    /*display: none;*/
    position: sticky;
    z-index: 10;
    height: 64px;
    width: 100%;
    margin: -64px auto 0;
    padding: 0 20px;
    background: $color_2;
    bottom: 0;
    height: 60px;
    margin: 0 auto -60px auto;
    padding: 10px 7px;
    z-index: 1;
    transform:translate3d(0,0,0);
    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        width: 178px;
        .link {
          width: 100%;
          height: 44px;
          .txt {
            font-size: 16px;
            padding-left: 24px;
            &::before {
              width: 19.6px;
              height: 15.7px;
            }
          }
        }
        &:nth-of-type(n+2) {
          margin-left: 5px;
          .link {
            .txt {
              padding-left: 20px;
              &::before {
                width: 14.4px;
                height: 23.7px;
              }
            }
          } 
        }
      }
    }
    .info {
      display: none;
    }
    .contact_btn {
      display: none;    
    }
  }
}

