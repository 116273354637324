/* ======================================================================
 base
====================================================================== */
html {
  font-size: 62.5%; // 1em = 10px
}

body {
  color: $color_1;
  font-family: $font_1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0,0,0,.01) 0 0 1px;
  @include pc_tab {
    width: 100%;
    /*min-width: $content_width;*/
  }
}

html, body {
  width: 100%;
  height: 100%;
}

* {
  backface-visibility: hidden;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* h1 - h6 */
h1, h2, h3, h4, h5, h6 {
  font-size: 1em;
}

/* a */
// 各ページで色を変更するときは@include a_tagを使用して上書きしてください。
a {
  text-decoration: none;
  &:link {
    color: $color_1;
    cursor: pointer;
  }
  &:visited {
    color: $color_1;
  }
  &:hover {
    color: $color_1;
  }
  &:active {
    color: $color_1;
  }
}

// モバイル時 電話番号カラー
a[href^="tel"] {
  color: $color_1;
}

/* Text Highlight */
// ::selection {
//   background-color: $color_1;
// }

/* button */
button {
  display: block;
}

textarea {
  resize: vertical;
}

select {
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $color_1;
  }
}

/* placeholder */
// 各ページで色を変更するときは@include placeholderを使用して上書きしてください。
input, textarea {
  @include placeholder($color_6);
  &:focus {
    @include placeholder($color_6);
  }
}

// ドラッグ禁止
img {
  pointer-events: none;
  user-select: none;
}
