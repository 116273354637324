/* ======================================================================
 component
====================================================================== */

/* =================================
 title
================================= */
.c_ttl_1 {
  margin: 0 0 40px;
  font-size: 43px;
  line-height: 65px;
  text-align: center;
  font-weight: bold;
  @include sp {
    font-size: 26px;
    line-height: 40px;
    margin: 0 0 30px;
  }
}

.c_ttl_2 {
  margin: 0 0 40px;
  font-size: 36px;
  line-height: 60px;
  text-align: center;
  font-weight: bold;
  .accent {
    color: $color_2;
  }
  @include sp {
    font-size: 26px;
    line-height: 40px;
    margin: 0 0 30px;
  }
}

.c_ttl_3 {
  margin: 0 0 40px;
  font-size: 30px;
  line-height: 37px;
  letter-spacing: 0.04em;
  font-weight: bold;
  position: relative;
  padding-left: 22px;
  &::before {
    content: "";
    height: 100%;
    width: 6px;
    background-color: $color_2;
    position: absolute;
    top: 2px;
    left: 0;
  }
  @include sp {
    font-size: 26px;
    line-height: 40px;
    margin: 0 0 28px;
  }
}

/* =================================
 buttons
================================= */
// c_gotop
.c_gotop {
}

.c_btn_1,
.c_btn_1:link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 400px;
  border-radius: 9999px;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  transition: 0.3s;
  border: 3px solid $color_4;
  margin: 0 auto;
  box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  background-color: #fff;
  .txt {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 16.6px;
    color: $color_4;
    &:before {
      content: "";
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 9999px;
      background: $color_4;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      left: -20px;
      transform: translateY(-50%);
    }
    &:after {
      content: "";
      display: block;
      height: 6.8px;
      width: 6.8px;
      border-bottom: solid 2px #fff;
      border-right: solid 2px #fff;
      background: transparent;
      transform: rotate(-45deg);
      position: absolute;
      left: -12px;
      top: 12px;
    }
  }
  &:visited,
  &:active {
    color: $color_4;
  }
  @include pc_tab {
    &:hover {
      background-color: $color_4;
      .txt {
        color: #fff;
        &:before {
          background: #fff;
        }
        &:after {
          border-bottom: solid 2px $color_4;
          border-right: solid 2px $color_4;
        }
      }
    }
  }
  @include sp {
    height: 80px;
    width: 100%;
    .txt {
      padding-left: 8px;
    }
  }
}

.c_btn_2,
.c_btn_2:link {
	display: flex;
	height: 40px;
	width: 100%;
	font-size: 18px;
	font-weight: bold;
	line-height: 30px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	position: relative;
	padding-bottom: 9px;
	align-items: center;
	justify-content: space-between;
  border-bottom: 1px solid $color_2;
  color: $color_4;
  &:before {
    content: "";
    display: block;
    height: 6.8px;
    width: 6.8px;
    border-bottom: solid 2px #fff;
    border-right: solid 2px #fff;
    background: transparent;
    transform: rotate(-45deg);
    position: absolute;
    right: 9px;
    top: 12px;
    z-index: 1;
  }
  &:after {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    border-radius: 9999px;
    background: $color_4;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 50%;
    right: 0;
  }

  &:visited,
  &:active {
    color: $color_4;
  }
  @include pc_tab {
    &:hover {
      opacity: 0.8;
    }
  }
  @include sp {
    font-size: 14px;
    padding-bottom: 5px;
    &:before {
      content: "";
      display: block;
      height: 4.8px;
      width: 4.8px;
      right: 6px;
      top: 15px;
      z-index: 1;
    }
    &:after {
      height: 17px;
      width: 17px;
    }
  }
}

.c_btn_3,
.c_btn_3:link {
	display: flex;
	width: 100%;
	font-size: 18px;
	line-height: 30px;
	transition: 0.3s;
	position: relative;
	align-items: center;
  color: $color_4;
  &:before {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    border-radius: 9999px;
    background: $color_4;
    margin-right: 5px;
  }
  &:after {
    content: "";
    display: block;
    height: 6.8px;
    width: 6.8px;
    border-bottom: solid 2px #fff;
    border-right: solid 2px #fff;
    background: transparent;
    transform: rotate(-45deg);
    position: absolute;
    left: 8px;
    top: 12px;
  }
  &:visited,
  &:active {
    color: $color_4;
  }
  @include pc_tab {
    &:hover {
      opacity: 0.8;
    }
  }
  @include sp {
  }
}

.c_btn_4,
.c_btn_4:link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 400px;
  border-radius: 9999px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  transition: 0.3s;
  margin: 0 auto;
  position: relative;
  background-color: $green;
  color: #fff;
  .txt {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 35.7px;
    &:before {
      content: "";
      background-image: url(../img/common/mail_icon.svg);
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      width: 25.7px;
      height: 20.5px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  &:visited,
  &:active {
    color: #fff;
  }
  @include pc_tab {
    &:hover {
      background-color: #8CB808;
    }
  }
  @include sp {
    height: 70px;
    width: 100%;
    &:before {
      margin: 0 10px 0 0;
    }
  }
}

.c_btn_5,
.c_btn_5:link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 178px;
  border-radius: 9999px;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  transition: 0.3s;
  margin: 0 auto;
  position: relative;
  background-color: $green;
  .txt {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 25px;
    color: #fff;
    &:before {
      content: "";
      background-image: url(../img/common/mail_icon.svg);
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      width: 19.6px;
      height: 15.7px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  &:visited,
  &:active {
    color: #fff;
  }
  @include pc_tab {
    &:hover {
      background-color: #8CB808;
    }
  }
  @include sp {
    height: 44px;
    &:before {
      margin: 0 10px 0 0;
    }
  }
}

.c_btn_6,
.c_btn_6:link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 178px;
  border-radius: 9999px;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  transition: 0.3s;
  margin: 0 auto;
  position: relative;
  background-color: #fff;
  border: 3px solid $color_4;
  .txt {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 25px;
    color: $color_4;
    &:before {
      content: "";
      background-image: url(../img/common/outlink_icon01.svg);
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  &:visited,
  &:active {
    color: #fff;
  }
  @include pc_tab {
    &:hover {
      background-color: $color_4;
      .txt {
        color: #fff;
        &:before {
          background-image: url(../img/common/outlink_icon01_on.svg);
        }
      }
    }
  }
  @include sp {
    height: 44px;
    &:before {
      margin: 0 10px 0 0;
    }
  }
}

.c_btn_7,
.c_btn_7:link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
  border-radius: 9999px;
  font-size: 30px;
  font-weight: bold;
  font-family: $font_2;
  line-height: 36px;
  transition: 0.3s;
  margin: 0 auto;
  position: relative;
  background-color: $color_5;
  .txt {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 29px;
    color: #fff;
    &:before {
      content: "";
      background-image: url(../img/common/tel_icon02.svg);
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      width: 19.1px;
      height: 31.3px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  &:visited,
  &:active {
    color: #fff;
  }
  @include pc_tab {
    &:hover {
      opacity: 0.8;
    }
  }
  @include sp {
    &:before {
    }
  }
}


.c_btn_8,
.c_btn_8:link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 400px;
  border-radius: 9999px;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  transition: 0.3s;
  margin: 0 auto;
  position: relative;
  background-color: $green;
  .txt {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 16.6px;
    color: #fff;
    &:before {
      content: "";
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 9999px;
      background: #fff;
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
    }
    &:after {
      content: "";
      display: block;
      height: 6.8px;
      width: 6.8px;
      border-bottom: solid 2px $green;
      border-right: solid 2px $green;
      background: transparent;
      transform: rotate(-45deg);
      position: absolute;
      right: -10px;
      top: 12px;
    }
  }
  &:visited,
  &:active {
    color: #fff;
  }
  @include pc_tab {
    &:hover {
      background-color: #8CB808;
      .txt {
        &:before {
        }
        &:after {
          border-bottom: solid 2px #8CB808;
          border-right: solid 2px #8CB808;
        }
      }
    }
  }
  @include sp {
    height: 70px;
    width: 100%;
    .txt {
      padding-right: 8px;
    }
  }
}


.c_btn_9,
.c_btn_9:link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 175px;
  border-radius: 9999px;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  transition: 0.3s;
  border: 3px solid $color_4;
  margin: 0 auto;
  position: relative;
  background-color: #fff;
  .txt {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 16.6px;
    color: $color_4;
    &:before {
      content: "";
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 9999px;
      background: $color_4;
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      left: -20px;
      transform: translateY(-50%);
    }
    &:after {
      content: "";
      display: block;
      height: 6.8px;
      width: 6.8px;
      border-bottom: solid 2px #fff;
      border-right: solid 2px #fff;
      background: transparent;
      transform: rotate(135deg);
      position: absolute;
      left: -10px;
      top: 12px;
    }
  }
  &:visited,
  &:active {
    color: $color_4;
  }
  @include pc_tab {
    &:hover {
      background-color: $color_4;
      .txt {
        color: #fff;
        &:before {
          background: #fff;
        }
        &:after {
          border-bottom: solid 2px $color_4;
          border-right: solid 2px $color_4;
        }
      }
    }
  }
  @include sp {
    height: 70px;
    width: 100%;
    .txt {
      padding-left: 8px;
    }
  }
}

.c_btn_10,
.c_btn_10:link {
	display: flex;
	width: 100%;
	font-size: 18px;
	font-weight: bold;
	line-height: 30px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	position: relative;
	align-items: center;
	justify-content: flex-end;
  color: $color_4;
  &:before {
    content: "";
    display: block;
    height: 6.8px;
    width: 6.8px;
    border-bottom: solid 2px #fff;
    border-right: solid 2px #fff;
    background: transparent;
    transform: rotate(-45deg);
    position: absolute;
    right: 9px;
    top: 12px;
    z-index: 1;
  }
  &:after {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    margin-left: 10px;
    border-radius: 9999px;
    background: $color_4;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 50%;
    right: 0;
  }

  &:visited,
  &:active {
    color: $color_4;
  }
  @include pc_tab {
    &:hover {
      opacity: 0.8;
    }
  }
  @include sp {
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color_2;
    height: 40px;
    padding-bottom: 5px;
    &:before {
      content: "";
      display: block;
      height: 4.8px;
      width: 4.8px;
      right: 6px;
      top: 14px;
      z-index: 1;
    }
    &:after {
      height: 17px;
      width: 17px;
      margin-left: 0;
    }
  }
}




/* =================================
 icon
================================= */
.c_ico_1 {
}

.c_ico_2 {
}

.c_ico_3 {
}

/* =================================
 table
================================= */
.c_table_1 {
}

.c_table_2 {
}

.c_table_3 {
}

/* =================================
 list
================================= */
.c_list_1 {
  .item {
    display: flex;
    width: 100%;
    padding: 17px 0;
    text-align: left;
    border-top: 1px solid #D6D6D6;
    &:last-of-type {
      border-bottom: 1px solid #D6D6D6;
    }
    .subttl {
      width: 150px;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      flex-shrink: 0;
    }
    .txt {
      font-size: 18px;
      line-height: 30px;
      .p {
        &:nth-of-type(n+2) {
          margin-top: 18px;
        }
      }
      .link {
        margin-top: 20px;
      }
    }
  }
  @include sp {
    .item {
      flex-direction: column;
      .subttl {
        width: auto;
      }
      .txt {
        margin-top: 10px;
        .link {
          margin-top: 10px;
        }
      }
    }
  }
}


// c_list_2
.c_list_2 {
  .item {
    padding-left: 22px;
    text-indent: -22px;
    &::before {
      content: "●";
      color: #4E7FB9;
      font-size: 12px;
      margin-right: 10px;
    }
  }
}

// c_list_3
.c_list_3 {
	display: flex;
	justify-content: center;
	margin: 0 auto;
  .item {
    position: relative;
    width: 226px;
    height: 66px;
    &::before {
      display: block;
      box-sizing: border-box;
      position: absolute;
      z-index: 2;
      left: calc(50% - 15px);
      width: 30px;
      height: 30px;
      border-radius: 9999px;
      background-color: #F2F2F2;
      content: '';
    }
    &:nth-of-type(n+2) {
      &::after {
        display: block;
        position: absolute;
        z-index: 1;
        top: 11px;
        left: -50%;
        width: 100%;
        height: 5px;
        background-color: #F2F2F2;
        content: '';
      }
    }
    .status  {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      .num {
        font-family: $font_2;
        margin-right: 10px;
      }
    }
    &.active {
      &::before {
        background-color: $color_4;
      }
      &:nth-of-type(n+2) {
        &::after {
          background-color: $color_4;
        }
      }

      .status {
        color: $color_4;
      }
    }
  }
  @include sp {
    max-width: 345px;
    .item {
      &::before {
        z-index: 0;
        left: calc(50% - 15px);
      }
      &:nth-of-type(1) {
        width: 90px;
      }
      &:nth-of-type(2) {
        width: 128px;
      }
      &:nth-of-type(3) {
        width: 90px;
      }
      &:nth-of-type(n+2) {
        margin-left: 24px;
        &::after {
          z-index: -1;
          width: 130px;
          left:-80px;
        }
      }
      .status  {
        font-size: 16px;
        .num {
          margin-right: 0.53vw;
        }
      }
    }
  }
  @include mq_max(374) {
    .item {
      &:nth-of-type(n+2) {
        &::after {
          width: 120px;
          left:-70px;
        }
      }
      .status  {
        font-size: 4.011vw;
      }
    }
  }


}

// c_list_4
.c_list_4 {
  .item {
    display: flex;
    width: 100%;
    padding: 20px 0;
    text-align: left;
    border-top: 1px solid #ccc;
    &:last-of-type {
      border-bottom: 1px solid #ccc;
    }
    .subttl {
      width: 212px;
      font-size: 18px;
      font-weight: bold;
      line-height: 28px;
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      label[for]:not([class="error"]){
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .required {
        color: #fff;
        background: #e04347;
        font-size: 13px;
        padding: 0 8px;
        line-height: 21px;
        display: block;
      }
    }

    .txt {
      font-size: 16px;
      line-height: 28px;
      margin-left: 36px;
      input[type="text"],
      input[type="email"] {
        width: 650px;
        height: 44px;
        padding: 10px 20px;
        border-radius: 6px;
        background: #F2F2F2;
        font-size: 16px;
        letter-spacing: 0.96px;
        line-height: 28px;
        transform: none;
        transition: none;
        text-align: left;
        &.name,
        &.tel{
          width: 325px;
        }
      }

      textarea {
        width: 650px;
        height: 240px;
        padding: 10px 20px;
        border-radius: 6px;
        background: #F2F2F2;
        font-size: 16px;
        letter-spacing: 0.96px;
        line-height: 28px;
        transform: none;
        transition: none;
      }
    }
  }

  @include mq_max(1184) {
    .item {
      .txt {
        input[type="text"],
        input[type="email"] {
          width: 100%;
        }

        textarea {
          width: 100%;
        }
      }
    }

  }

  @include sp {
    .item {
      flex-direction: column;
      .subttl {
        width: auto;
        justify-content: flex-start;
        label[for]:not([class="error"]){
          width: auto;
        }
        .required {
          margin-left: 10.5px;
        }
      }
      .txt {
        margin-left: 0;
        margin-top: 15px;
        input[type="text"],
        input[type="email"] {
          width: 100%;
          &.name,
          &.tel{
            width: 100%;
          }
        }

        textarea {
          width: 100%;
          height: 180px;
        }
      }
    }
  }
}

/* =================================
 item
================================= */
// c_item_1
.c_item_1 {
}

// c_item_2
.c_item_2 {
}

// c_item_3
.c_item_3 {
}

/* =================================
 pager
================================= */
.c_pager_num {
  padding: 50px 0 80px;
  .inner {
    width: $content_width;
    margin: 0 auto;
  }
  .pagination_list {
    display: flex;
    justify-content: center;
    .item {
      position: relative;
      width: 42px;
      height: 42px;
      border-radius: 4px;
      background: #fff;
      border: 1px solid #ccc;
      box-shadow: 2px 3px 1px rgba(0, 0, 0, 0.08);
      font-size: 16px;
      font-family: $font_2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:nth-of-type(n+2) {
        margin-left: 5px;
      }
      .link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      &.active {
        background-color: $color_4;
        color: #fff;
        .link {
          &:hover {
            color: #fff;
          }
        }
      }
      &.prev,
      &.next {
        background-color: #F2F2F2;
        &::before {
          content: "";
          display: block;
          height: 12px;
          width: 12px;
          border-bottom: solid 2px #222;
          border-right: solid 2px #222;
          background: transparent;
          position: absolute;
          top: 14px;
          pointer-events: none;
        }
        &.end {
          &::after {
            content: "";
            display: block;
            height: 12px;
            width: 12px;
            border-bottom: solid 2px #222;
            border-right: solid 2px #222;
            background: transparent;
            position: absolute;
            top: 14px;
            left: 23px;
            pointer-events: none;
          }
          &.prev {
            &::before {
              left: 13px;
            }
            &::after {
              transform: rotate(135deg);
              left: 20px;
            }
          }
          &.next {
            &::before {
              left: 9px;
            }
            &::after {
              transform: rotate(-45deg);
              left: 16px;
            }
          }
        }
      }
      &.prev {
        &::before {
          transform: rotate(135deg);
          left: 17px;
        }
      }
      &.next {
        &::before {
          transform: rotate(-45deg);
          left: 11px;
        }
      }
    }
  }

  @include mq_max(1184) {
    .inner {
      width: 100%;
      padding: 0 15px;
    }
  }

  @include pc_tab {
    .pagination_list {
      .item {
        .link {
          &:hover {
            opacity: 0.7;
          }
        }
        &.active {
          .link {
            &:hover {
              opacity: 1;
            }
          }
        }
        &.prev,
        &.next {
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

  }

  @include sp {
  padding: 40px 0 50px;
    .inner {
      width: 100%;
      padding: 0 15px;
    }
    .pagination_list {
      .item {
        width: 35px;
        height: 35px;
        font-size: 12px;
        &:nth-of-type(n+2) {
          margin-left: 3px;
        }
        .link {
        }
        &.active {
          background-color: $color_4;
          color: #fff;
          .link {
            &:hover {
            }
          }
        }
        &.prev,
        &.next {
          background-color: #F2F2F2;
          &::before {
            height: 10px;
            width: 10px;
            top: 12px;
          }
          &.end {
            &::after {
              height: 10px;
              width: 10px;
              top: 12px;
              left: 23px;
            }
            &.prev {
              &::before {
                left: 13px;
              }
              &::after {
                left: 20px;
              }
            }
            &.next {
              &::before {
                left: 9px;
              }
              &::after {
                left: 16px;
              }
            }
          }
        }
        &.prev {
          &::before {
            left: 14px;
          }
        }
        &.next {
          &::before {
            left: 11px;
          }
        }
      }
    }
  }

    @include mq_max(374) {
    .pagination_list {
      .item {
        width: 9.07vw;
        height: 9.07vw;
        font-size: 3.2vw;
        &:nth-of-type(n+2) {
          margin-left: 0.8vw;
        }
        &.prev,
        &.next {
          &::before {
            height: 2.67vw;
            width: 2.67vw;
            top: 3.2vw;
          }
          &.end {
            &::after {
              height: 2.67vw;
              width: 2.67vw;
              top: 3.2vw;
              left: 6.13vw;
            }
            &.prev {
              &::before {
                left: 3.47vw;
              }
              &::after {
                left: 5.33vw;
              }
            }
            &.next {
              &::before {
                left: 2.4vw;
              }
              &::after {
                left: 4.27vw;
              }
            }
          }
        }
        &.prev {
          &::before {
            left: 3.73vw;
          }
        }
        &.next {
          &::before {
            left: 2.93vw;
          }
        }
      }
    }
  }


}

.c_pager_prev_next {
}

/* =================================
 link
================================= */
.c_out_link_1 {
  position: relative;
  padding-right: 18px;
  &::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background: url(../img/common/outlink_icon01.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  }
}

/* =================================
 bg
================================= */
.c_wave_bg_1 {
  position: relative;
  width: 100%;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 458px;
    background: url(../img/common/wave_bg01.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  @include sp {
    &:before {
      background-image: url(../img/common/wave_bg01_sp.png);
      background-position: bottom center;
      width: 100%;
    }
  }
}

.c_wave_bg_2 {
  position: relative;
  width: 100%;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 467px;
    background: url(../img/common/wave_bg02.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
  }
  @include sp {
    &:before {
      background-image: url(../img/common/wave_bg02_sp.png);
      background-position: bottom center;
      width: 100%;
    }
  }
}


/* =================================
 block
================================= */
.c_case_sec1 {
  width: 100%;
  position: relative;
  padding: 70px 0;
  .inner {
    position: relative;
    max-width: $content_width;
    margin: 0 auto;
  }
  .ttl {
    margin: 0 0 40px;
    font-size: 43px;
    font-weight: bold;
    line-height: 62px;
    text-align: center;
    position: relative;
    .sub {
      position: relative;
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 13px;
      margin-bottom: 13px;
      display: block;
      color: $color_2;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: 93px;
        background-color: $color_2;
      }
    }
    .main {
      display: block;
    }
  }
  .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    > .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 340px;
      border-radius: 10px;
      position: relative;
      .category {
        position: absolute;
        min-width: 95px;
        height: 29px;
        top: 10px;
        left: 10px;
        content: "";
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        padding: 0 6px 1px;
        z-index: 1;
        &.interior {
          background-color: $color_4;
        }
        &.exterior {
          background-color: $color_2;
        }
        &.plan {
          background-color: $color_5;
        }
        &.others {
          background-color: $color_1;
        }
      }

      .img {
        border-radius: 10px;
      }
      &.item:nth-of-type(n+2) {
        margin-left: 40px;
      }
      .box {
        margin-top: 20px;
        width: 100%;
        .subttl {
          font-size: 20px;
          line-height: 30px;
          font-weight: bold;
          min-height: 60px;
        }
        .cate_list {
          display: flex;
          flex-wrap: wrap;
          margin: 12px 0 12px -5px;
          .item {
            border: 1px solid $color_2;
            padding: 5px;
            border-radius: 4px;
            color: $color_2;
            font-size: 14px;
            margin-bottom: 5px;
            margin-left: 5px;
            &:nth-of-type(n+2) {
            }
          }
        }
      }
    }
  }
  .link {
    margin-top: 70px;
  }

  @include mq_max(1184) {
    .inner {
      max-width: unset;
      padding: 0 15px;
    }
    .list {
      > .item {
        width: calc(100% / 3);
        .img {
          width: 100%;
        }
      }
    }
  }

  @include pc_tab {
    .list {
      > .item {
        transition: .4s;
        .item_link {
          .photo {
            overflow: hidden;
            border-radius: 10px;
            .img {
              transition: .4s;

              /*opacity: 0.8;*/
            }
          }
          .box {
            .c_btn_2 {
              &:hover {
                opacity: 1;
              }
            }
          }
        }
        &:hover {
          opacity: .8;
          .img{
            transform: translate3d(0,0,0) scale(1.07);
            transition: transform .4s;
          }
        }
      }
    }
  }


  @include sp {
    padding: 50px 0;
    .inner {
      margin: 0 15PX;
      padding: 0;
    }
    .ttl {
      font-size: 26px;
      line-height: 40px;
      margin: 0 0 30px;
      text-align: left;
      .sub {
        font-size: 16px;
        padding-bottom: 0;
        margin-bottom: 15px;
        &::before {
          content: none;
        }
        .line {
          width: 93px;
          display: inline-block;
          background-color: #032076;
          height: 1px;
          position: relative;
          vertical-align: middle;
          margin-top: -1px;
          margin-left: 15px;
        }
      }
    }
    .list {
      flex-direction: column;
      > .item {
        /*flex-direction: row;*/
        width: 100%;
        /*border-radius: 6px;*/
        .item_link {
          width: 100%;
        }
        .category {
          min-width: 80px;
          height: 24px;
          top: 5px;
          left: 5px;
          font-size: 14px;
          padding: 2px 4px;
        }
        .img {
          border-radius: 6px;
        }
        &.item:nth-of-type(n+2) {
          margin-left: 0;
          margin-top: 30px;
        }
        .box {
          margin-top: 0;
          .head {
            display: flex;
            align-items: center;
            .img {
              width: 165px;
              margin-right: 15px
            }
          }
          .subttl {
            font-size: 16px;
            line-height: 26px;
            min-height: unset;
            width: 165px;
          }
          .cate_list {
            display: flex;
            margin: 10px 0 5px -5px;
            .item {
              font-size: 13px;
            }
          }
        }
      }
    }
    .link {
      margin-top: 40px;
    }
  }
}

.c_case_sec2 {
  width: 100%;
  position: relative;
  .inner {
    position: relative;
    max-width: $content_width;
    margin: 0 auto;
  }
  .list {
    > .item {
      .link_wrap {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        position: relative;
        border: 1px solid #ccc;
      }
      .category {
        position: absolute;
        min-width: 95px;
        height: 39px;
        top: 0;
        left: 0;
        content: "";
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        padding: 0 20px 1px;
        border-radius: 10px 0 0 0;
        z-index: 2;
        &.interior {
          background-color: $color_4;
        }
        &.exterior {
          background-color: $color_2;
        }
        &.plan {
          background-color: $color_5;
        }
        &.others {
          background-color: $color_1;
        }
      }

      .img {
        border-radius: 10px;
      }
      &.item:nth-of-type(n+2) {
        margin-top: 30px;
      }
      .box {
        margin-left: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .subttl {
          font-size: 20px;
          line-height: 30px;
          font-weight: bold;
        }
        .cate_list {
          display: flex;
          margin: 15px 0;
          .item {
            border: 1px solid $color_2;
            padding: 5px;
            border-radius: 4px;
            color: $color_2;
            font-size: 14px;
            &:nth-of-type(n+2) {
              margin-left: 5px;
            }
          }
        }
        .detail_txt {
          font-size: 16px;
          line-height: 27px;
        }
        .link {
        }

      }
    }
  }

  @include mq_max(1184) {
    .inner {
      width: 100%;
      padding: 0 15px;
    }
  }

  @include pc_tab {
    .list {
      > .item {
        .link_wrap {
          transition: 0.3s;
           .photo {
               overflow: hidden;
               min-width: 404px;
               border-radius: 10px;
             .img {
               transition: 0.3s;
               min-width: 404px;
               height: auto;
             }
           }
           &:hover {
             opacity: 0.8;
             .photo {
               .img {
                  transform: translate3d(0,0,0) scale(1.07);
                  transition: transform .4s;
               }
             }
           }
          }
          .box {
            .link {
              &:hover {
                opacity: 1;
              }
            }

          }

      }
    }
  }

  @include sp {
    .inner {
      width: 100%;
      padding: 0 15px;
    }
    .list {
      > .item {
        .link_wrap {
          flex-direction: column;
          border-radius: 0;
          border: 0;
          padding: 0;
        }
        .category {
          border-radius: 0;
          min-width: 80px;
          height: 24px;
          top: 5px;
          left: 5px;
          font-size: 14px;
          padding: 2px 4px;
        }
        &.item:nth-of-type(n+2) {
          /*margin-top: 30px;*/
        }
        .box {
          margin-left: 0;
          .txt_box {
            .head {
              display: flex;
              align-items: center;
              .img {
                width: 165px;
                height: auto;
                margin-right: 15px;
              }
            }
          }
          .subttl {
            font-size: 16px;
            line-height: 26px;
          }
          .cate_list {
            margin: 10px 0 13px;
            .item {
              font-size: 13px;
            }
          }
          .detail_txt {
            font-size: 14px;
            line-height: 23px;
          }
          .link {
            /*margin-top: 15px;*/
          }

        }
      }
    }
  }
}

.c_products_sec {
  width: 100%;
  position: relative;
  padding: 0 0 80px;
  .ttl {
    min-height: 174px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin: 0 0 40px;
    font-size: 36px;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .inner {
    position: relative;
    max-width: $content_width;
    margin: 0 auto;
  }
  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      /*display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;*/
      width: 530px;
      position: relative;
      border-radius: 10px;
      background: #fff;
      border: 1px solid #ccc;
      /*padding: 20px;*/
      &:nth-of-type(2n) {
        margin-left: 40px;
      }
      &:nth-of-type(n+3) {
        margin-top: 40px;
      }
      .item_link {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
      .box {
        width: 100%;
        .img {
          border-radius: 10px;
        }
        .head {
          margin-top: 20px;
          .subttl {
            font-size: 20px;
            line-height: 30px;
            font-weight: bold;
          }
          .txt {
            font-size: 16px;
            line-height: 27px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .link {
    margin-top: 70px;
  }

  @include mq_max(1184) {
    .inner {
      max-width: 100%;
      padding: 0 15px;
    }
    .list {
      .item {
        width: 47%;
        .box {
          .img {
            width: 100%;
          }
        }
      }
    }
  }

  @include pc_tab {
    .list {
      .item {
        transition: .4s;
        .item_link {
          transition: .4s;
          .box {
            width: 100%;
            .photo {
              overflow: hidden;
              border-radius: 10px;
              .img {
                transition: .4s;
                height: auto;
                width: 100%;
              }
            }
          }
        }
      }
      .link {
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @include sp {
    padding: 0 0 30px;
    .ttl {
      min-height: 105px;
      margin: 0 0 15px;
      font-size: 26px;
    }
    .inner {
      max-width: unset;
      padding: 0 15px;
    }
    .list {
      flex-direction: column;
      flex-wrap: nowrap;
      .item {
        width: auto;
        &:nth-of-type(2n) {
          margin-left: 0;
          margin-top: 15px;
        }
        &:nth-of-type(n+3) {
          margin-top: 15px;
        }
        .item_link {
          padding: 15px;
          justify-content: flex-start;
        }
        .box {
          .img {
            width: 100%;
          }
          .head {
            margin-top: 15px;
            .subttl {
              font-size: 18px;
            }
            .txt {
            }
          }
        }
      }
    }
    .link {
      margin-top: 20px;
      border-bottom: none;
      justify-content: flex-end;
      height: auto;
      padding-bottom: 0;
      &::before {
        top: 12px;
      }
      &::after {
        margin-left: 7px;
      }
    }
  }
}

/* =================================
 accordion
================================= */
.c_accordion_nav {
  @include sp {
    border: 3px solid $color_4;
    border-radius: 25px;
    padding: 0 23px;
    .accordion_btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: $color_4;
      text-align: center;
      position: relative;
      height: 44px;
      cursor: pointer;
      &::before {
        content: "";
        display: block;
        height: 5px;
        width: 5px;
        border-bottom: solid 2px #fff;
        border-right: solid 2px #fff;
        background: transparent;
        transform: rotate(-315deg);
        position: absolute;
        right: 6px;
        top: 19px;
        z-index: 1;
        transform-origin:3px 3px;
        transition: 0.3s;
      }
      &::after {
        content: "";
        position: absolute;
        display: block;
        height: 17px;
        width: 17px;
        border-radius: 9999px;
        background: $color_4;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
      &.selected {
        &::before {
          transform: rotate(-135deg);
        }
      }
    }
    .list {
      display: none;
      .item {
        .link {
          border-top: 1px solid $color_4;
          border-bottom: none;
          border-right: none;
          border-left: none;
          border-radius: 0;
          height: 44px;
          box-shadow:none;
          .txt {
            padding-left: 0;
            font-size: 16px;
            &::before,
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
}
